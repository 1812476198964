import { Alert, Backdrop, Button, Card, CardActions, CardContent, CircularProgress, FormControl, FormLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { checkAuth } from '../Auth/checkAuth';
import { formHeadingCss } from '../util/formHeadingCss';
import { formSubmitButtonCss } from '../util/formSubmitButtonCss';

export default function CreatePrediction({ setToken }) {

    const [predictionName, setPredictionName] = useState("");
    const [description, setDescription] = useState("");
    const [projectInfoId, setProjectInfoId] = useState("");
    const [uploadFileName, setUploadFileName] = useState([]);

    const [projectInfoList, setProjectInfoList] = useState([]);
    const [datasetInfoList, setDatasetInfoList] = useState([]);

    const [inputDisable, setInputDisable] = useState(false);
    const [processingFile, setProcessingFile] = useState(false);

    const [uploadFileStatus, setUploadFileStatus] = useState(false);
    const [successNotification, setSuccessNotification] = useState(false);
    const [successNotificationMessage, setSuccessNotificationMessage] = useState("");


    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const token = sessionStorage.getItem("token");

    const checkAuthentication = checkAuth({ token, setToken, navigate })

    useEffect(() => {

        checkAuthentication()
            .then(result => {
                fetchAllProjectList();
            });

    }, [])

    useEffect(() => {

        if (projectInfoList.length !== 0) {
            setDatasetInfoList(projectInfoList.filter(val => val.project_info_id == projectInfoId)[0].dataset_info_list)
        }

    }, [projectInfoId])




    const fetchAllProjectList = () => {

        fetch('/api/operation/retrieve_project_info/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => {
                setProjectInfoList(data.message);
                return data;
            })
    }


    const submitProject = (event) => {
        event.preventDefault();
        setLoading(true);
        setTimeout(() => {
            fetchToCreatePrediction();
        }, 4000);

    }

    const fetchToCreatePrediction = () => {

        var timestamp = new Date().toISOString().substring(0, 19);

        var raw = JSON.stringify(
            {
                "prediction_name": predictionName,
                //"prediction_name": predictionName + " | " + timestamp,
                "project_info_id": projectInfoId,
                "description": description,
                "input_filenames_csv": uploadFileName.toString()
            });


        var myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        // console.log(raw);

        fetch('/api/operation/run_prediction/', requestOptions)
            .then(res => res.json())
            .then(data => {
                return data;
            }).then(result => checkCreate(result));
    };

    const checkCreate = (result) => {
        navigate("/predict");
    }

    const uploadFile = (event) => {

        setInputDisable(true);
        setProcessingFile(true);
        setSuccessNotification(false);

        var formdata = new FormData();
        formdata.append("dataset_file", event.target.files[0]);

        var requestOptions = {
            method: 'POST',
            headers: { "Authorization": `Bearer ${token}` },
            body: formdata,
            redirect: 'follow'
        };

        fetch("/api/datasets/upload_dataset_file/", requestOptions)
            .then(response => response.json())
            .then(result => {
                setProcessingFile(false);
                if (result.status == "SUCCESS") {
                    var temp = uploadFileName
                    temp.push(result.message)
                    setUploadFileName(temp)
                    setUploadFileStatus(true);
                    setSuccessNotification(true);
                    setSuccessNotificationMessage(event.target.files[0].name + " uploaded Successfully")
                    setInputDisable(false);
                } else {
                    setSuccessNotification(true);
                    setSuccessNotificationMessage("Upload failed. Please retry")
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleSuccessNotificationClose = () => {
        setSuccessNotification(false);
    }

    return (
        <div>
            <h3 style={{
                textAlign: "justify"
            }}> Prediction Creation</h3>
            <div style={{ textAlign: "justify" }}>
                <p>Instructions:</p>
                <ul>
                    <li>The input data file must be Comma-separated values (CSV) format.</li>
                    <li>The example of input data is in here (hyperlink of a modal).</li>
                    <li>A tutorial video is here.</li>
                </ul>
            </div>
            <div>
                <form
                    onSubmit={submitProject}>
                    <Typography
                         style={formHeadingCss}
                    >
                        Create a Prediction for a Project
                    </Typography>

                    <Card style={{
                        width: '50%',
                        alignContent: 'left',
                        textAlign: 'left',
                        margin: 'auto',
                        marginBottom: '2%',
                        boxShadow:' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)',
                        fontWeight: '500' 
                    }}>
                        <CardContent>
                            <FormControl >
                                <FormLabel>Prediction Name</FormLabel>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    value={predictionName}
                                    onChange={e => { setPredictionName(e.target.value) }}
                                    required
                                />
                            </FormControl>

                            <br></br><br></br>

                            <FormControl >
                                <FormLabel>Choose Project</FormLabel>
                                <Select
                                    value={projectInfoId}
                                    onChange={e => { setProjectInfoId(e.target.value) }}
                                    required
                                >
                                    {projectInfoList.map((val) =>
                                        <MenuItem key={val.project_info_id} value={val.project_info_id}> {val.project_name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>

                            {datasetInfoList.map(dataset => <>
                                <br></br><br></br>
                                <FormControl key={dataset.dataset_name} >
                                    <FormLabel> Upload test dataset for {dataset.dataset_name}</FormLabel>
                                    <input type="file" disabled={inputDisable} key={dataset.dataset_name} onChange={(event) => { uploadFile(event) }} accept=".csv"></input>
                                </FormControl>

                            </>)}

                            {processingFile && <>
                                <br></br>
                                <br></br>
                                <Alert severity="info" sx={{ width: '60%' }}>
                                    Uploading in process.. Please wait..!!!
                                </Alert>
                            </>}

                            {
                                successNotification && ((uploadFileStatus) ? (<>
                                    <br></br>
                                    <br></br>
                                    <Alert onClose={handleSuccessNotificationClose} severity="success" sx={{ width: '60%' }}>
                                        {successNotificationMessage}
                                    </Alert>
                                </>) : (<>
                                    <br></br>
                                    <br></br><Alert onClose={handleSuccessNotificationClose} severity="error" sx={{ width: '60%' }}>
                                        {successNotificationMessage}
                                    </Alert></>))

                            }
                            <br></br><br></br>
                            <FormControl >
                                <FormLabel>Description</FormLabel>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    value={description}
                                    onChange={e => { setDescription(e.target.value) }}
                                />
                            </FormControl>

                        </CardContent>

                        <CardActions>
                            <Button style={formSubmitButtonCss} type='submit' size="large" disabled={loading}>Sumbit</Button>
                        </CardActions>

                    </Card >
                </form>
            </div>
            <div>
                {loading ? (<div><Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                    <Typography> Dont refresh while creating prediction</Typography>
                </Backdrop></div>) : ''}
            </div>

        </div >
    )
}
