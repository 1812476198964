import { ListSubheader } from '@material-ui/core';
import { Button, Card, CardActions, CardContent, FormControl, Backdrop, CircularProgress, FormControlLabel, FormLabel, FormGroup, Checkbox, MenuItem, Radio, RadioGroup, Select, Slider, TextField, Typography } from '@mui/material';
import $ from 'jquery';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { checkAuth } from '../Auth/checkAuth';
import { formHeadingCss } from '../util/formHeadingCss';
import { formSubmitButtonCss } from '../util/formSubmitButtonCss';

export default function CreateProject({ setToken }) {

    const [projectName, setProjectName] = useState("");
    const [modelInfoId, setModelInfoId] = useState("");
    const [dataset, setDataset] = useState([]);
    const [learningRate, setLearningRate] = useState(0.00001);
    const [earlyStoppingPatience, setEarlyStoppingPatience] = useState(10);
    const [description, setDescription] = useState("");
    const [publicModelInfoList, setPublicModelInfoList] = useState([]);
    const [privateModelInfoList, setPrivateModelInfoList] = useState([]);
    const [lossListModel, setLossListModel] = useState([]);
    const [metricsListModel, setMetricsListModel] = useState([]);
    const [mlHparamModes, setMlHparamModes] = useState([]);
    const [datasetList, setDatasetList] = useState();
    const [modeKey, setModeKey] = useState("");
    const [epochs, setEpochs] = useState(1000);
    const [loss, setLoss] = useState("");
    const [metrics, setMetrics] = useState("");
    const [batchSize, setBatchSize] = useState(256);

    const [allDatasetTypeList, setAllDatasetTypeList] = useState([]);
    const [dataType, setDataType] = useState([])


    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const token = sessionStorage.getItem('token');


    const checkAuthentication = checkAuth({ token, setToken, navigate })

    useEffect(() => {

        checkAuthentication()
            .then(result => {
                fetchdatasetList();
            });

    }, [])


    useEffect(() => {
        if (modelInfoId !== "") {
            fetchLossToModel();
            fetchDatasetsRelatedToModel();
            fetchMetricsToModel();
            fetchAllMLModelHparamMode();
        }
    }, [modelInfoId]);

    useEffect(() => {
        if (dataType.length > 0) {
            fetchPulicAllModelList();
            fetchPrivateAllModelList();
        }
    }, [dataType])



    const dataTypeHandler = (event) => {

        if (event.target.checked) {
            setDataType(dataType => [
                ...dataType,
                event.target.value
            ]);

        } else {
            const newdataType = dataType.filter((item) => item !== event.target.value);
            setDataType(newdataType);

        }
    }


    const fetchdatasetList = () => {

        fetch('/api/information/fetch_all_dataset_type/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setAllDatasetTypeList(data);
                return data;
            })
    }


    const fetchPulicAllModelList = () => {

        let dataparam = dataType.toString().replaceAll(",", "|");

        fetch(`/api/modeling/retrieve_ml_model_info_summary?dataset_type_key_vlsv=${dataparam}&is_grouped=1`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setPublicModelInfoList(data.message);
                return data;
            })
    }

    const fetchPrivateAllModelList = () => {

        let dataparam = dataType.toString().replaceAll(",", "|");
        
        fetch(`/api/modeling/retrieve_ml_model_info_summary?dataset_type_key_vlsv=${dataparam}&is_grouped=1`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => {
                setPrivateModelInfoList(data.message);
                return data;
            })
    }

    const fetchDatasetsRelatedToModel = () => {
        fetch(`/api/datasets/retrieve_dataset_related_to_model?model_info_id=${modelInfoId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == "SUCCESS") {
                    setDatasetList(data.message);
                }
                return data;
            })
    }


    const fetchLossToModel = () => {
        fetch(`/api/information/fetch_type_based_loss_function/?model_info_id=${modelInfoId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setLossListModel(data);
                return data;
            })
    }

    const fetchMetricsToModel = () => {
        fetch(`/api/information/fetch_type_based_metric/?model_info_id=${modelInfoId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setMetricsListModel(data);
                return data;
            })
    }

    const fetchAllMLModelHparamMode = () => {

        fetch(`/api/information/fetch_all_ml_model_hparam_mode/?model_info_id=${modelInfoId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setMlHparamModes(data.message);
                return data;
            })
    }


    const modelHandler = (e) => {
        setDatasetList([]);
        setModelInfoId(e.target.value)
    }

    // const datasetHandler = (key, event) => {

    //     var dict = {};
    //     dict[key] = event.target.value;

    //     var newDataset = Object.assign({}, dict, dataset);

    //     setDataset(newDataset);
    // }

    const datasetHandler = (key, e) => {

        var temp = dataset

        temp.push(e.target.value)
        setDataset(temp)
    }

    const submitProject = (event) => {
        event.preventDefault();
        setLoading(true);
        setTimeout(() => {
            fetchToCreateModel();
        }, 4000);

    }

    const fetchToCreateModel = () => {

        var timestamp = new Date().toISOString().substring(0, 19);

        var trainingParameters =
        {
            "learning_rate": learningRate,
            "early_stopping_patience": earlyStoppingPatience,
            "epochs": epochs,
            "batch_size": batchSize,
            "loss_key": loss,
            "metric_key": metrics
        }

        var raw = JSON.stringify(
            {
                "project_name": projectName,
               // "project_name": projectName + " | " + timestamp,
                "model_info_id": modelInfoId,
                "dataset_info_id_csv": dataset.toString(),
                "description": description,
                "mode_key": modeKey,
                "training_parameters": trainingParameters
            });


        var myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        // console.log(raw);

        fetch('/api/operation/run_project/', requestOptions)
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                return data;
            }).then(result => checkCreate(result));
    };

    const checkCreate = (result) => {
        // setLoading(false)
        navigate("/projects");
    }

    return (
        <div>
            <h3 style={{
                textAlign: "justify"
            }}> Project Creation</h3>
            <div style={{ textAlign: "justify" }}>
                <p>Instructions:</p>
                <ul>
                    <li>Diagram tutorial is in here.</li>
                    <li>A tutorial video is here </li>
                </ul>
            </div>
            <div>
                <form
                    onSubmit={submitProject}>
                    <Typography
                        style={formHeadingCss}>
                        Project Creation
                    </Typography>
                    <Card style={{
                        width: '50%',
                        alignContent: 'left',
                        textAlign: 'left',
                        margin: 'auto',
                        marginBottom: '2%',
                        boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)',
                        fontWeight: '500'
                    }}>
                        <CardContent>
                            <FormControl required>
                                <FormLabel>Project name</FormLabel>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    value={projectName}
                                    onChange={e => { setProjectName(e.target.value) }}
                                    required
                                />
                            </FormControl>

                            <br></br> <br></br>

                            <FormControl required>
                                <FormLabel>Choose data type (Choose min 1)</FormLabel>
                                <FormGroup >
                                    {allDatasetTypeList.map((data) =>
                                        <FormControlLabel key={data.id} control={<Checkbox
                                            value={data.dataset_type_key}
                                            name={data.dataset_type_key}
                                            onChange={(e) => { dataTypeHandler(e) }} />} label={"(" + data.dataset_type_key.substring(0, 1).toUpperCase() + ") - " + data.dataset_type_name}
                                        />
                                    )}
                                </FormGroup>
                            </FormControl >

                            <br></br> <br></br>

                            <FormControl required>
                                <FormLabel>Choose Model</FormLabel>
                                <Select
                                    value={modelInfoId}
                                    onChange={e => { modelHandler(e) }}
                                    required
                                >
                                    <ListSubheader style={{ fontWeight: 'bold', color: '#2A3F54' }}>Public Models</ListSubheader>
                                    {!publicModelInfoList.PUBLIC == "" &&
                                        publicModelInfoList.PUBLIC.map((val) =>
                                            <MenuItem key={val.model_info_id} value={val.model_info_id}> {val.model_name_with_data_type}</MenuItem>
                                        )
                                    }

                                    <ListSubheader style={{ fontWeight: 'bold', color: '#2A3F54' }}>Private Models</ListSubheader>
                                    {!privateModelInfoList.PRIVATE == "" &&
                                        privateModelInfoList.PRIVATE.map((val) =>
                                            <MenuItem key={val.model_info_id} value={val.model_info_id}> {val.model_name_with_data_type}</MenuItem>
                                        )
                                    }

                                </Select>
                            </FormControl>

                            {!datasetList == "" && (Object.entries(datasetList).map(([key, values]) => (
                                <div key={key}>
                                    <br></br><br></br>
                                    <FormControl required>
                                        <FormLabel>Dataset type</FormLabel>
                                        <TextField
                                            id="filled-read-only-input"
                                            defaultValue={key}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="filled"
                                        />
                                    </FormControl>
                                    <FormControl >
                                        <FormLabel>Choose Dataset</FormLabel>
                                        <Select
                                            defaultValue=""
                                            onChange={e => { datasetHandler(key, e) }}
                                            required
                                        >
                                            {Object.entries(values).map(([key, value]) => (
                                                <MenuItem key={key} value={value.dataset_info_id}> {value.dataset_name}</MenuItem>

                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )))}

                            {/* <FormControl >
                        <FormLabel>Hyperparameter Option</FormLabel>
                        <Select
                            defaultValue=""
                            onChange={e => { setModeKey(e.target.value) }}
                            required
                        >
                            {Object.entries(mlHparamModes).map(([key, value]) => (
                                <MenuItem key={key} value={value.mode_key} disabled={!value.CHOOSEABLE}> {value.mode_name} {value.SELECTED && ('(selected)')}</MenuItem>

                            ))}
                        </Select>
                    </FormControl> */}

                            {
                                (!datasetList == "") && (<>
                                    <br></br><br></br>
                                    <FormControl required>
                                        <FormLabel >Hyperparameter Option</FormLabel>
                                        <RadioGroup
                                            row
                                            value={modeKey}
                                            onChange={e => { setModeKey(e.target.value) }}
                                        >
                                            {Object.entries(mlHparamModes).map(([key, value]) => (
                                                <FormControlLabel key={key} value={value.mode_key} disabled={!value.CHOOSEABLE} control={<Radio required />} label={(value.SELECTED) ? (value.mode_name + " - (Default)") : (value.mode_name)} />

                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </>
                                )
                            }

                            <br></br><br></br>
                            <FormControl>
                                <FormLabel >Description</FormLabel>
                                <TextField
                                    type="text"
                                    value={description}
                                    onChange={e => { setDescription(e.target.value) }}
                                />
                            </FormControl>
                            <br></br><br></br>

                            {(modeKey == "DEFAULT_MODE" || modeKey == "CUSTOMIZE_HYPERPARAMETERS") ? (
                                <>
                                    <Typography variant="h6"> Training Parameters </Typography>

                                    <FormControl required>
                                        <FormLabel>Loss</FormLabel>
                                        <Select
                                            defaultValue=""
                                            value={loss}
                                            onChange={e => { setLoss(e.target.value) }}
                                            required
                                        >
                                            {lossListModel.map((data) =>
                                                <MenuItem key={data.id} value={data.loss_key} > {data.loss_name}</MenuItem >
                                            )}
                                        </Select>
                                    </FormControl>
                                    <br></br><br></br>
                                    <FormControl required>
                                        <FormLabel>Metrics</FormLabel>
                                        <Select
                                            defaultValue=""
                                            value={metrics}
                                            onChange={e => { setMetrics(e.target.value) }}
                                            required
                                        >
                                            {metricsListModel.map((data) =>
                                                <MenuItem key={data.id} value={data.metric_key} > {data.metric_name}</MenuItem >
                                            )}
                                        </Select>
                                    </FormControl>
                                    <br></br><br></br>
                                    <FormControl required>
                                        <FormLabel>Learning rate</FormLabel>
                                        <Select
                                            value={learningRate}
                                            onChange={e => { setLearningRate(e.target.value) }}
                                            defaultValue='0.1'
                                            required
                                        >
                                            <MenuItem value={0.00001}>0.00001</MenuItem>
                                            <MenuItem value={0.0001}>0.0001</MenuItem>
                                            <MenuItem value={0.001}>0.001</MenuItem>
                                            <MenuItem value={0.003}>0.003</MenuItem>
                                            <MenuItem value={0.01}>0.01</MenuItem>
                                            <MenuItem value={0.03}>0.03</MenuItem>
                                            <MenuItem value={0.1}>0.1</MenuItem>
                                            <MenuItem value={0.3}>0.3</MenuItem>
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br></br><br></br>
                                    <FormControl required>
                                        <FormLabel>Early stopping patience</FormLabel>
                                        <Select
                                            value={earlyStoppingPatience}
                                            onChange={e => { setEarlyStoppingPatience(e.target.value) }}
                                            defaultValue='10'
                                            required
                                        >
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl required>
                                        <Typography variant="h8"> Epoch : </Typography>
                                        <Slider
                                            name='epoch_num'
                                            style={{ color: '#1165f1' }}
                                            defaultValue={1000}
                                            //   getAriaValueText={valuetext}
                                            aria-labelledby="discrete-slider-small-steps"
                                            step={200}
                                            marks
                                            min={200}
                                            max={2001}
                                            valueLabelDisplay="on"
                                            onChange={(_, value) => setEpochs(value)}
                                        />
                                        <br></br>
                                        <Typography variant="h8">Batch size :</Typography>
                                        <Slider
                                            name='batch_num'
                                            style={{ color: 'rgba(239, 28, 65, 0.97)' }}
                                            defaultValue={256}
                                            //   getAriaValueText={valuetext}
                                            aria-labelledby="discrete-slider-small-steps"
                                            step={32}
                                            marks
                                            min={32}
                                            max={513}
                                            valueLabelDisplay="on"
                                            onChange={(_, value) => setBatchSize(value)}
                                        />
                                    </FormControl>
                                </>
                            ) : ''}

                        </CardContent>

                        <CardActions>
                            <Button style={formSubmitButtonCss} type='submit' size="large" disabled={loading}>Sumbit</Button>
                        </CardActions>

                    </Card >
                </form>
            </div>
            <div>
                {loading ? (<div><Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                    <Typography> Dont refresh while creating project</Typography>
                </Backdrop></div>) : ''}
            </div>

        </div >
    )
}
