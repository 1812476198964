import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { Cancel } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import DoneOutlineSharpIcon from '@mui/icons-material/DoneOutlineSharp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import { Alert, Autocomplete, Button, FormControl, FormLabel, IconButton, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from "@mui/system";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { checkAuth } from '../Auth/checkAuth';
import { formHeadingCss } from '../util/formHeadingCss';
import { formSubmitButtonCss } from '../util/formSubmitButtonCss';
import { imageStyleInModal, imageStyleInUserGuide } from '../util/imageStyleInUserGuide';


export default function CreateDS({ setToken }) {

    const [state, setState] = useState({
        dataset_name: "",
        data_type: "",
        upload_data: "",
        dataTrainUrl: "",
        server_upload_file_name: "",
        keyOfInputUrl: 0,
        description: "",
        datasource_type: "",
        error_feedback_msg: '',
        task_id: '',
        task_percentage: 0,
        task_log: '',
        dataset_list: [],
        tags: [],
        tagValues: ["Other"]
    });

    const [loading, setLoading] = useState(false);
    const [reloadTimer, setReloadTimer] = useState(5);
    const [uploadFileStatus, setUploadFileStatus] = useState(false);
    const [successNotification, setSuccessNotification] = useState(false);
    const [successNotificationMessage, setSuccessNotificationMessage] = useState("");
    const [id, setId] = useState("");
    const [inputValue, setInputValue] = useState("");

    const token = sessionStorage.getItem("token");

    let navigate = useNavigate();

    const checkAuthentication = checkAuth({ token, setToken, navigate })

    useEffect(() => {

        checkAuthentication()
            .then(result => {
                fetchAllDataset();
            });

    }, [])

    setInterval(() => timer(), 2000)

    useEffect(() => {

        removeTags(state.tags);

        if (state.datasource_type === "PLANT") {

            setState((state) => ({
                ...state,
                tagValues: ["Acoerulea", "Acomosus", "Ahalleri", "Ahypochondriacus", "Alyrata", "Athaliana", "Atrichopoda", "Bdistachyon", "Boleraceacapitata", "BrapaFPsc", "Bstacei", "Bstricta", "Cclementina", "Cgrandiflora", "Cpapaya", "Creinhardtii", "Crubella", "Csativus", "Csinensis", "CsubellipsoideaC", "Dcarota", "Dsalina", "Egrandis", "Esalsugineum", "Fvesca", "Gmax", "Graimondii", "Kfedtschenkoi", "Klaxiflora", "Lusitatissimum", "Macuminata", "Mdomestica", "Mesculenta", "Mguttatus", "Mpolymorpha", "MpusillaCCMP1545", "MspRCC299", "Mtruncatula", "Oeuropaea", "Olucimarinus", "Osativa", "Othomaeum", "Phallii", "Ppatens", "Ppersica", "Ptrichocarpa", "Pvirgatum", "Pvulgaris", "Rcommunis", "Sbicolor", "Sfallax", "Sitalica", "Slycopersicum", "Smoellendorffii", "SpolyrhiZa", "Spurpurea", "Stuberosum", "Sviridis", "Tcacao", "Tpratense", "Vcarteri", "Vvinifera", "Zmarina", "Zmays"]
            }))
        }

        if (state.datasource_type === "ANIMAL") {

            setState((state) => ({
                ...state,
                tagValues: ["Ailuropoda melanoleuca", "Anas platyrhynchos", "Anolis carolinensis", "Astyanax mexicanus", "Bos taurus", "Caenorhabditis elegans", "Callithrix jacchus", "Canis familiaris", "Carlito syrichta", "Cavia aperea", "Cavia porcellus", "Chinchilla lanigera", "Chlorocebus sabaeus", "Choloepus hoffmanni", "Ciona intestinalis", "Ciona savignyi", "Cricetulus griseus", "Danio rerio", "Dasypus novemcinctus", "Dipodomys ordii", "Drosophila melanogaster", "Echinops telfairi", "Equus caballus", "Erinaceus europaeus", "Felis catus", "Ficedula albicollis", "Fukomys damarensis", "Gadus morhua", "Gallus gallus", "Gasterosteus aculeatus", "Gorilla", "Heterocephalus glaber", "Ictidomys tridecemlineatus", "Jaculus jaculus", "Latimeria chalumnae", "Lepisosteus oculatus", "Loxodonta africana", "Macaca mulatta", "Meleagris gallopavo", "Mesocricetus auratus", "Microcebus murinus", "Microtus ochrogaster", "Monodelphis domestica", "Mus caroli", "Mus pahari", "Mus spretus", "Mustela putoriusFuro", "Myotis lucifugus", "Nannospalax galili", "Nomascus leucogenys", "Notamacropus eugenii", "Ochotona princeps", "Octodon degus", "Oreochromis niloticus", "Ornithorhynchus anatinus", "Oryctolagus cuniculus", "Oryzias latipes", "Otolemur garnettii", "Ovis aries", "Pan troglodytes", "Papio anubis", "Pelodiscus sinensis", "Peromyscus maniculatus bairdii", "Petromy zon marinus"]
            }))
        }

        if (state.datasource_type === "HUMAN") {

            setState((state) => ({
                ...state,
                tagValues: ["Homo sapiens"]
            }))
        }
    }, [state.datasource_type])


    const fetchAllDataset = () => {

        fetch('/api/information/fetch_all_dataset_type/')
            .then(response => { return response.json() })
            .then(result => setState((state) => ({
                ...state,
                dataset_list: result
            })))
    }


    const timer = () => {

        if (reloadTimer > 0) {
            setReloadTimer(reloadTimer - 1)
        } else {
            setReloadTimer(5);

            if (loading) {
                fetchTaskInfo();
            }
        }

    }

    const fetchTaskInfo = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: { "Authorization": `Bearer ${token}` }

        };
        fetch(`/api/datasets/retrieve_create_data_task_info/?task_id=${id}`, requestOptions)
            .then(response => response.text())
            .then(result => addStatus(result))
            .catch(error => console.log('error', error));
    }

    const addStatus = (result) => {
        let status = JSON.parse(result).status;
        let message = JSON.parse(result).message;

        if (status === 'SUCCESS' || status === 'PENDING' || status === 'RUNNING') {

            let task_log = '';
            if (message.task_log !== '') {
                let idx = 1;
                message.task_log.forEach((element, _) => {
                    task_log = <span>{task_log}Step {idx}: {element}<br></br></span>;
                    idx += 1;
                })
            }

            setState((state) => ({
                ...state,
                task_log: task_log,
                task_percentage: message.task_percentage.toFixed(1),
            }))
        } else {
            setState((state) => ({
                ...state,
                task_log: 'Initializing',
                task_percentage: 0,
            }))
        }
    }

    const handleChange = (name) => event => {
        setState((state) => ({
            ...state,
            [name]: event.target.value
        }));
    };

    const fetchToCreate = () => {

        setLoading(true);
        // setState((state) => ({
        //     ...state,
        //     loading: true,
        // }))

        var timestamp = new Date().toISOString().substring(0, 19);
        var task_id = uuidv4();

        setId(task_id);

        setState((state) => ({
            ...state,
            task_id: task_id,
            task_percentage: 0,
            task_log: '',
        }))

        let myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        let raw = JSON.stringify({
            "dataset_name": state.dataset_name,
          //  "dataset_name": state.dataset_name + " | " + timestamp,
            "dataset_type_key": state.data_type,
            "training_dataset_url": state.dataTrainUrl,
            "training_dataset_server_path": state.server_upload_file_name,
            "description": state.description,
            "test_dataset_url": '',
            "task_id": task_id,
            "datasource_type": state.datasource_type,
            "tags": state.tags,
            "permission": 0
        });

        // console.log(raw);
        // console.log(myHeaders);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        var promise = Promise.race([
            fetch('/api/datasets/create_training_dataset/', requestOptions),
            new Promise((resolve, reject) =>
                // 20*60*1000 second => 20 mins.
                setTimeout(() => reject(new Error('Timeout')), 50 * 60 * 1000)
            )
        ])
            .then(response => {
                response.json();
                // console.log(response);
            })
            .then(result => checkCreate(result))
            .catch(error => { showErrorMessage(error); console.log(error) });

    }

    const checkCreate = (result) => {

        navigate('/datasets');
        setLoading(false);
        window.location.reload();

        // setState((state) => ({
        //     ...state,
        //     loading: false
        // }))
    }

    const showErrorMessage = (error) => {
        if (error.toString() === "Error: Timeout") {
            setState((state) => ({
                ...state,
                error_feedback_msg: 'The dataset is still creating. It will be shown in summary page once it is done.',
                // loading: false,
            }))

            setLoading(false);
        }
        else {
            setState((state) => ({
                ...state,
                error_feedback_msg: 'Internal error. Please contact administrator.',
                // loading: false,
            }))

            setLoading(false);
        }

    }


    const submit = event => {
        event.preventDefault();
        fetchToCreate();
    };


    const addExampleUrl = () => {
        // set URL
        var url = ""
        if (state.data_type === "ZYGOSITY") {
            url = "https://de.cyverse.org/dl/d/2FFA3458-2DDF-4FCB-B1FE-DF5C08A0BD28/protein.train.csv"
        }
        else if (state.data_type === "SNP") {
            url = "https://data.cyverse.org/dav-anon/iplant/home/soykb/G2PDeep/v2.data/TCGA_large/GBMLGG.SNP.survival_3.training.csv"

        } else if (state.data_type === "CNV") {
            url = "https://data.cyverse.org/dav-anon/iplant/home/soykb/G2PDeep/v2.data/TCGA_large/GBMLGG.CNV.survival_3.training.csv"

        } else if (state.data_type === "MIRNA_EXPRESSION") {
            url = "https://data.cyverse.org/dav-anon/iplant/home/soykb/G2PDeep/v2.data/TCGA_large/GBMLGG.miRNA.survival_3.training.csv"

        }
        else if (state.data_type === "GENE_EXPRESSION") {
            url = "https://data.cyverse.org/dav-anon/iplant/home/soykb/G2PDeep/v2.data/TCGA_large/GBMLGG.RNASeq.survival_3.training.csv"

        }
        else if (state.data_type === "PROTEIN_EXPRESSION") {
            url = "https://data.cyverse.org/dav-anon/iplant/home/soykb/G2PDeep/v2.data/TCGA_large/GBMLGG.ProteinExpression.survival_3.training.csv"

        }
        else if (state.data_type === "DNA_METHYLATION") {
            url = "https://data.cyverse.org/dav-anon/iplant/home/soykb/G2PDeep/v2.data/TCGA_large/GBMLGG.methylation450.survival_3.training.csv"

        } else {
            url = ""
        }
        setState((state) => ({
            ...state,
            dataTrainUrl: url,
            keyOfInputUrl: state.keyOfInputUrl + 1,
        }))
    };

    const onChangeDataUrl = ({ target: { value } }) => {
        setState((state) => ({
            ...state,
            dataTrainUrl: value,
            keyOfInputUrl: state.keyOfInputUrl + 1
        }))
    };

    const removeTags = (value) => {
        const newtags = state.tags.filter((val) => val !== value);
        {
            setState((state) => ({
                ...state,
                tags: newtags
            }))
        }
    };

    const addTags = (event) => {
        event.preventDefault();
        if (event.target.value !== "") {
            var temp = state.tags;
            temp.push(event.target.value);
            setState((state) => ({
                ...state,
                tags: temp
            }))
            event.target.value = "";
            setInputValue("");
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    function isCSVFile(fileName) {
        const acceptedExtensions = ['.csv'];
        const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
        return acceptedExtensions.includes(fileExtension);
    }
    const uploadFile = (event) => {

        var formdata = new FormData();
        formdata.append("dataset_file", event.target.files[0]);
      //  for (let i = 0; i < event.target.files.length; i++) {
      //      formdata.append("dataset_file", event.target.files[i]);
      //    }

        var requestOptions = {
            method: 'POST',
            headers: { "Authorization": `Bearer ${token}` },
            body: formdata,
            redirect: 'follow'
        };

        fetch("/api/datasets/upload_dataset_file/", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "SUCCESS") {
                    setState((state) => ({
                        ...state,
                        server_upload_file_name: result.message
                    }))
                    setUploadFileStatus(true);
                    setSuccessNotification(true);
                    setSuccessNotificationMessage(event.target.files[0].name + " uploaded Successfully")
                   // setSuccessNotificationMessage("Files uploaded successfully");
                  //  console.log("Uploaded files:", event.target.files);
                } else {
                    setSuccessNotification(true);
                    setSuccessNotificationMessage("Upload failed. Please retry")
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleSuccessNotificationClose = () => {
        setSuccessNotification(false);
    }

    const [openDialog, setOpenDialog] = useState(false);
    const handleOpen = () => {
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            <h3 style={{
                textAlign: "justify"
            }}> Create New Dataset</h3>
            <div style={{ textAlign: "justify" }}>
                <p>Instructions:</p>
                <ul>
                    <li>The input data file must be Comma-separated values (CSV) format.</li>
                    <li>The example of input data is in <a href="#" onClick={handleOpen}>here</a>.</li>
                    <li>A tutorial video is here </li>
                </ul>
            </div>

            <div>
                <Dialog open={openDialog} style={{
                    right: '0%'
                }}>
                    <DialogTitle  >
                        <Typography
                            style={{
                                width: '50%',
                                margin: 'auto',
                            }}
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                            align="center"
                        >
                            How to Create New Dataset
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>

                        <ul>
                            <li>Name your dataset under Dataset Name.</li>
                            <li>Provide link to training and validation dataset.</li>
                            <ul>
                                <li>Refer to Show data format if you are confused about the format.</li>
                                <li>Only one link to your csv file is needed, the ratio between training and validation dataset is determined automatically. Or you can determine the ratio by yourself later in <strong><a href="#datasetdetails"><code>Details</code></a></strong>. You may also click <strong>Link to example</strong>, if you prefer using our publicly available dataset, SoyNAM - Protein quantitative protein trait.</li>
                            </ul>
                            <li>Write the description of your dataset, which is optional, for your own reference only.</li>
                            <li>Click on <strong>Create</strong> button to create your own dataset.</li>
                            <img style={imageStyleInModal} src="https://de.cyverse.org/dl/d/B11748AB-5273-41A5-AEFA-8A194AD0226B/create_dataset.png" alt="create_dataset" />
                        </ul>
                        <ul>
                            <li>Name your dataset under Dataset Name.</li>
                            <li>Provide link to training and validation dataset.</li>
                            <ul>
                                <li>Refer to Show data format if you are confused about the format.</li>
                                <li>Only one link to your csv file is needed, the ratio between training and validation dataset is determined automatically. Or you can determine the ratio by yourself later in <strong><a href="#datasetdetails"><code>Details</code></a></strong>. You may also click <strong>Link to example</strong>, if you prefer using our publicly available dataset, SoyNAM - Protein quantitative protein trait.</li>
                            </ul>
                            <li>Write the description of your dataset, which is optional, for your own reference only.</li>
                            <li>Click on <strong>Create</strong> button to create your own dataset.</li>
                            <img style={imageStyleInUserGuide} src="https://de.cyverse.org/dl/d/B11748AB-5273-41A5-AEFA-8A194AD0226B/create_dataset.png" alt="create_dataset" />
                        </ul>
                        <ul>
                            <li>Name your dataset under Dataset Name.</li>
                            <li>Provide link to training and validation dataset.</li>
                            <ul>
                                <li>Refer to Show data format if you are confused about the format.</li>
                                <li>Only one link to your csv file is needed, the ratio between training and validation dataset is determined automatically. Or you can determine the ratio by yourself later in <strong><a href="#datasetdetails"><code>Details</code></a></strong>. You may also click <strong>Link to example</strong>, if you prefer using our publicly available dataset, SoyNAM - Protein quantitative protein trait.</li>
                            </ul>
                            <li>Write the description of your dataset, which is optional, for your own reference only.</li>
                            <li>Click on <strong>Create</strong> button to create your own dataset.</li>
                            <img style={imageStyleInUserGuide} src="https://de.cyverse.org/dl/d/B11748AB-5273-41A5-AEFA-8A194AD0226B/create_dataset.png" alt="create_dataset" />
                        </ul>
                        <ul>
                            <li>Name your dataset under Dataset Name.</li>
                            <li>Provide link to training and validation dataset.</li>
                            <ul>
                                <li>Refer to Show data format if you are confused about the format.</li>
                                <li>Only one link to your csv file is needed, the ratio between training and validation dataset is determined automatically. Or you can determine the ratio by yourself later in <strong><a href="#datasetdetails"><code>Details</code></a></strong>. You may also click <strong>Link to example</strong>, if you prefer using our publicly available dataset, SoyNAM - Protein quantitative protein trait.</li>
                            </ul>
                            <li>Write the description of your dataset, which is optional, for your own reference only.</li>
                            <li>Click on <strong>Create</strong> button to create your own dataset.</li>
                            <img style={imageStyleInUserGuide} src="https://de.cyverse.org/dl/d/B11748AB-5273-41A5-AEFA-8A194AD0226B/create_dataset.png" alt="create_dataset" />
                        </ul>
                    </DialogContent>
                </Dialog>

            </div>

            <div >
                <form
                    onSubmit={submit.bind(this)}
                    autoComplete="off" >
                    <Typography
                        style={formHeadingCss}
                    >
                        Create New Dataset
                    </Typography>
                    <Card style={{
                        width: '50%',
                        alignContent: 'left',
                        textAlign: 'left',
                        margin: 'auto',
                        marginBottom: '2%',
                        boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)',
                        fontWeight: '500'
                    }}>
                        <CardContent>
                            <FormControl >
                                <FormLabel required>Dataset Name</FormLabel>
                                <TextField
                                    id="dataset"
                                    variant="outlined"
                                    type="text"
                                    value={state.dataset_name}
                                    onChange={handleChange("dataset_name")}
                                    required
                                />
                            </FormControl>

                            <br></br>
                            <br></br>

                            <FormControl >
                                <FormLabel required>Choose data type</FormLabel>
                                <Select
                                    name="data_type"
                                    value={state.data_type}
                                    onChange={handleChange("data_type")}
                                    required>

                                    {state.dataset_list.map((data) =>
                                        <MenuItem key={data.id} value={data.dataset_type_key}> {data.dataset_type_name}</MenuItem >
                                    )}
                                </Select>
                            </FormControl>

                            <br></br>
                            <br></br>

                            <FormControl >
                                <FormLabel required>Upload training or validation dataset</FormLabel>
                                <Select
                                    name='upload_data'
                                    value={state.upload_data}
                                    onChange={handleChange("upload_data")}
                                    required
                                >
                                    <MenuItem value="input">Transfer file from shared link</MenuItem>
                                    <MenuItem value="upload">Upload file from local machine</MenuItem>
                                </Select>
                            </FormControl>


                            {state.upload_data === 'upload' ? (
                                <>
                                    <br></br><br></br>

                                    <input type="file"
                                        id="avatar" onChange={(event) => { uploadFile(event) }} accept=".csv"></input>
                            {state.uploadedFileName && !state.uploadedFileName.toLowerCase().endsWith('.csv') && (
                            <p style={{ color: 'red' }}>Please upload a valid CSV file.</p>
                        )}
                                </>

                            ) : ''}


                            {state.upload_data === 'input' ? (
                                <div style={{ marginTop: '8px' }}>
                                    <button type='button' onClick={addExampleUrl} >Link to example</button>
                                    <br></br>
                                    <Input placeholder="Input a link to your data"
                                        key={state.keyOfInputUrl}
                                        defaultValue={state.dataTrainUrl}
                                        onChange={onChangeDataUrl} />
                                </div>
                            ) : ''}
                            {
                                successNotification && ((uploadFileStatus) ? (<>
                                    <br></br>
                                    <br></br>
                                    <Alert onClose={handleSuccessNotificationClose} severity="success" sx={{ width: '100%' }}>
                                        {successNotificationMessage}
                                    </Alert>
                                </>) : (<>
                                    <br></br>
                                    <br></br><Alert onClose={handleSuccessNotificationClose} severity="error" sx={{ width: '100%' }}>
                                        {successNotificationMessage}
                                    </Alert></>))

                            }

                            <br></br>
                            <br></br>


                            <FormControl >
                                <FormLabel required>Please select a type of data source </FormLabel>
                                <Select
                                    name='datasource_type'
                                    value={state.datasource_type}
                                    onChange={handleChange("datasource_type")}
                                    required
                                >
                                    <MenuItem value="PLANT">Plant and Crops</MenuItem>
                                    <MenuItem value="HUMAN">Humans</MenuItem>
                                    <MenuItem value="ANIMAL">Animal and Pets</MenuItem>
                                </Select>
                            </FormControl>
                            <br></br>
                            <br></br>

                            {/* Tag field */}

                            <div style={{ display: 'flex' }}>
                                {/* <FormLabel >Tags</FormLabel> */}
                                <Autocomplete
                                    disablePortal
                                    options={state.tagValues}
                                    sx={{ width: 300 }}
                                    inputValue={inputValue}
                                    onInputChange={handleInputChange}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        variant='standard'
                                        size='small'
                                        sx={{ margin: "1rem 0" }}
                                        margin='none'
                                        display='inline-block'
                                        placeholder={state.tags.length < 3 ? "Enter tags" : ""}
                                        onKeyDown={(event) => event.key === 'Enter' ? addTags(event) : null}
                                    />}
                                />

                                <Tooltip title="Please select upto 3 tag values and Press Enter" style={{
                                    display: 'inline-block', width: '10%'
                                }} >
                                    <IconButton>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }}>
                                {

                                    Object.entries(state.tags).map(([key, value]) => (
                                        <Box
                                            key={key}
                                            sx={{
                                                background: "#283240",
                                                height: "100%",
                                                display: "flex",
                                                padding: "0.4rem",
                                                margin: "0 0.5rem 0 0",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                color: "#ffffff",
                                            }}
                                        >
                                            <Stack direction='row' gap={1}>
                                                <Typography>{value}</Typography>
                                                <Cancel
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        removeTags(value);
                                                    }}
                                                />
                                            </Stack>
                                        </Box>
                                    ))
                                }
                            </Box>

                            <br></br>
                            <br></br>

                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                type="text"
                                value={state.description}
                                onChange={handleChange("description")}
                            />

                        </CardContent>
                        <CardActions>
                            <Button variant="contained" style={formSubmitButtonCss} type="submit" disabled={loading} size="large">
                                Create
                            </Button>
                        </CardActions>
                    </Card>

                </form>
            </div>

            {
                loading === true ? (
                    <div align='center'>
                        <p style={{
                            fontWeight: 'bold'
                        }}>Note: Please don't refresh the page during dataset creation.</p>
                        <p style={{
                            fontWeight: 'bold'
                        }}> CURRENT STATUS : </p>
                        <p> Downloading Data {state.task_percentage < 25 ? (<LoopSharpIcon style={{
                            color: '#4595FF'
                        }} />) : (<DoneOutlineSharpIcon style={{
                            color: '#48B02C'
                        }} />)} Loading the Data {state.task_percentage < 50 ? (<LoopSharpIcon style={{
                            color: '#4595FF'
                        }} />) : (<DoneOutlineSharpIcon style={{
                            color: '#48B02C'
                        }} />)} Validating the data {state.task_percentage < 75 ? (<LoopSharpIcon style={{
                            color: '#4595FF'
                        }} />) : (<DoneOutlineSharpIcon style={{
                            color: '#48B02C'
                        }} />)} Success {state.task_percentage < 100 ? (<LoopSharpIcon style={{
                            color: '#4595FF'
                        }} />) : (<DoneOutlineSharpIcon style={{
                            color: '#48B02C'
                        }} />)}</p>

                        <LinearProgress variant="determinate" sx={{
                            width: '50%',
                            marginBottom: '5%',
                            paddingLeft: '20%'
                        }} value={Number(state.task_percentage)} />
                    </div>) : ''
            }
        </div >
    )
}

