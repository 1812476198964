import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';

import { precictionMdbTableColumns } from '../util/tableColumnsDetails';
import { MDBDataTableV5 } from "mdbreact";
import { logoutFakeUser } from '../Auth/logoutFakeUser';

export default function PrivateTestPredictionTable({ setToken }) {

    const [predictionInfo, setPredictionInfo] = useState([]);

    let navigate = useNavigate();

    const token = sessionStorage.getItem('token');

    const didMount = useRef(true);

    var timer;

    useEffect(() => {
        timer = setInterval(() => {
            getPredictionInfo();
        }, 15000);
        return () => clearTimeout(timer);
    }, [])

    useEffect(() => {

        if (didMount.current) {
            getPredictionInfo();
            didMount.current = false;
        }

    }, [])


    const getPredictionInfo = () => {

        fetch(`/api/operation/retrieve_all_prediction_info`,
            {
                headers: { "Authorization": `Bearer ${token}` }
            })
            .then(res => res.json())
            .then(data => {

                if (!data.detail == '') {
                    logoutFakeUser(setToken, navigate);
                }

                // console.log(data.message)

                setPredictionInfo(data.message);
                return data;
            })
    }

    const handleRowClick = (rowData) => {
        navigate(`/prediction_info`, { state: { row: rowData } })
    };


    const addDataToRows = (rows) => {
        if (!Array.isArray(rows) || rows.length === 0) {
            return []; // Return an empty array if 'rows' is not valid
        }
        return rows.map((row) => {
            const modelDBinfo = row.project_info?.modeldb_info || []; // Default to an empty array if 'modeldb_info' is missing
            return {
                ...row,
                projectname: row.project_info.project_name,
                clickEvent: () => handleRowClick(row),
            };
        });
    };

    return (
        <div className="App wrapper" style={{
            padding: '1%',
            boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)'
        }}>

            <h4 style={{
                fontWeight: '400',
                color: 'rgb(42, 63, 84)',
                textAlign: 'left'
            }}> Predictions Summary</h4>

            <MDBDataTableV5
                hover
                bordered
                striped
                small
                entriesOptions={[5, 10, 15, 20]}
                entries={5}
                data={{
                    columns: precictionMdbTableColumns, rows: addDataToRows(predictionInfo)
                }}
            />
            <p style={{ color: 'red', fontWeight: 'bold', textAlign: 'left' }}>* Auto refresh in every 15 seconds</p>

        </div>
    )
}
