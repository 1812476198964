export const dataDisplay = (json) => {
    return (
        <table className="styled-table">
            <thead>
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(json).map(([key, value]) => (
                    // console.log(key, value),
                    <tr className="active-row" key={key}>
                        <td>{key}</td>
                        <td style={{ maxWidth: '350px', wordWrap: 'break-word' }}>{value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};