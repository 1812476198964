import { Alert, Backdrop, Button, Card, CardActions, CardContent, CircularProgress, FormControl, FormLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton'
import $ from 'jquery';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import DefaultHyperParam from './DefaultHyperParam';
import DualCNNHyperParams from './DualCNNHyperParams';
import MultiCNNHyperParams from './MultiCNNHyperParams';
import SingleCNNHyperParams from './SingleCNNHyperParams';
import { checkAuth } from '../Auth/checkAuth';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { imageStyleInUserGuide } from '../util/imageStyleInUserGuide';
import { imageStyleInModal } from '../util/imageStyleInUserGuide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { formHeadingCss } from '../util/formHeadingCss';
import { formSubmitButtonCss } from '../util/formSubmitButtonCss';

export default function CreateModel({ setToken }) {

    const [modelName, setModelName] = useState("");
    const [dataType, setDataType] = useState([]);
    const [inputdataType, setInputdataType] = useState([]);
    const [datasetList, setDatasetList] = useState([]);
    const [task, setTask] = useState("");
    const [model, setModel] = useState("");
    const [hyperParameter, setHyperParameter] = useState("");
    const [description, setDescription] = useState("");
    const [hyperParamvalues, setHyperParamvalues] = useState({
        maxLayerNum: 3,
        tower1Num: 1,
        tower2Num: 1,
        tower3Num: 1,
        fCnnLayerNum: 1,
        tower1: [],
        tower2: [],
        tower3: [],
        fCn: []
    });

    const [modelTasknames, setModelTasknames] = useState([]);
    const [mlModelTypes, setMlModelTypes] = useState([]);
    const [mlHparamModes, setMlHparamModes] = useState([]);

    const [dataLoading, setDataLoading] = useState(false);

    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);

    const [failAlert, setFailAlert] = useState(false);
    const [failMessage, setFailMessage] = useState("The number of filters in the last layers for both the left and right tower must be the same.");

    const navigate = useNavigate();

    const token = sessionStorage.getItem('token');

    const checkAuthentication = checkAuth({ token, setToken, navigate })

    useEffect(() => {

        checkAuthentication()
            .then(result => {
                fetchdatasetList();
                fetchAllModelTaskName();
                fetchAllMLModelHparamMode();
                fetchMlModelTypes();
            });
    }, [])


    const fetchdatasetList = () => {

        fetch('/api/information/fetch_all_dataset_type/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setDatasetList(data);
                return data;
            })
    }

    const fetchAllModelTaskName = () => {

        fetch('/api/information/fetch_model_task_names/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setModelTasknames(data);
                return data;
            })
    }

    const fetchAllMLModelHparamMode = () => {

        fetch('/api/information/fetch_all_ml_model_hparam_mode/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setMlHparamModes(data.message);
                return data;
            })
    }


    function fetchMlModelTypes() {

        fetch(`/api/information/fetch_model_types_subset?dataset_type_key_csv=${inputdataType.toString()}&task_name=${task}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                setMlModelTypes(data);
                return data;
            })
    }

    const fetchToCreate = () => {

        var timestamp = new Date().toISOString().substring(0, 19);

        var tower1_filters_arr = new Array();
        var tower1_kernel_size_arr = new Array();
        var tower2_filters_arr = new Array();
        var tower2_kernel_size_arr = new Array();
        var tower3_filters_arr = new Array();
        var tower3_kernel_size_arr = new Array();
        var fcn_size_arr = new Array();

        $("input[name='tower1_filters[]']").each(function () {
            tower1_filters_arr.push(parseInt($(this).val()));
        });
        $("input[name='tower1_kernel_size[]']").each(function () {
            tower1_kernel_size_arr.push(parseInt($(this).val()));
        });
        $("input[name='tower2_filters[]']").each(function () {
            tower2_filters_arr.push(parseInt($(this).val()));
        });
        $("input[name='tower2_kernel_size[]']").each(function () {
            tower2_kernel_size_arr.push(parseInt($(this).val()));
        });
        $("input[name='tower3_filters[]']").each(function () {
            tower3_filters_arr.push(parseInt($(this).val()));
        });
        $("input[name='tower3_kernel_size[]']").each(function () {
            tower3_kernel_size_arr.push(parseInt($(this).val()));
        });
        $("input[name='num_fcn[]']").each(function () {
            fcn_size_arr.push(parseInt($(this).val()));
        });

        //fetch to create
        var myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        var tower_filters_lists = new Array();
        tower_filters_lists.push(tower1_filters_arr);
        tower_filters_lists.push(tower2_filters_arr);
        tower_filters_lists.push(tower3_filters_arr);

        var tower_kernel_size_lists = new Array();
        tower_kernel_size_lists.push(tower1_kernel_size_arr);
        tower_kernel_size_lists.push(tower2_kernel_size_arr);
        tower_kernel_size_lists.push(tower3_kernel_size_arr);


        var modelHyperParameters = JSON.stringify(
            {
                "tower_filters_lists": tower_filters_lists,
                "tower_kernel_size_lists": tower_kernel_size_lists,
                "dnn_size_list": fcn_size_arr,
            }
        )

        var raw = JSON.stringify(
            {
                "model_name": modelName,
               // "model_name": modelName + " | " + timestamp,
                "task_name": task,
                "permission": 0,
                "description": description,
                "dataset_type_key_csv": dataType.toString(),
                "label_type_key": "DISCRETE",
                "model_type_key": model,
                "mode_key": hyperParameter,

                "model_hyperparameters": modelHyperParameters
            });

        // console.log(raw);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch('/api/modeling/create_ml_model_info/', requestOptions)
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                return data;
            }).then(result => checkCreate(result));

        // else {
        //     setFailAlert(true);
        //     setDataLoading(false);
        //     return;
        // }
    }

    const checkCreate = (result) => {
        navigate("/models");
    }


    const submitModel = (event) => {
        event.preventDefault();
        setDataLoading(true);
        setTimeout(() => {
            fetchToCreate();
        }, 4000);

    }

    useEffect(() => {
        fetchMlModelTypes();
    }, [dataType, task])


    const dataTypeHandler = (event) => {

        // setMlModelTypes([]);
        setModel("");
        setTask("");
        setHyperParameter("");

        if (event.target.checked) {
            setDataType(dataType => [
                ...dataType,
                event.target.value
            ]);
            setInputdataType(inputDatatype => [
                ...inputDatatype,
                event.target.name
            ]);
        } else {
            const newdataType = dataType.filter((item) => item !== event.target.value);
            setDataType(newdataType);

            const newInputdataType = inputdataType.filter((item) => item !== event.target.name);
            setInputdataType(newInputdataType);
        }

        // fetchMlModelTypes();

    }

    useEffect(() => {
        if (dataType.length > 0) {
            setIsCheckBoxChecked(true);
        } else {
            setIsCheckBoxChecked(false);
        }

    }, [dataType])


    const modelhandler = (e) => {
        setHyperParameter("");
        setModel(e.target.value)
    }


    const taskTypehandler = (event) => {
        // setMlModelTypes([]);
        setModel("");
        setTask(event.target.value);
        setHyperParameter("");
        // fetchMlModelTypes();
    }


    const [openDialog, setOpenDialog] = useState(false);
    const handleOpen = () => {
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };

    const hyperParameterHandler = (event) => {
        setHyperParameter(event.target.value);
    }

    return (
        <>
            <div>
                <h3 style={{
                    textAlign: "justify"
                }}> Model Creation</h3>
                <div style={{ textAlign: "justify" }}>
                    <p>Instructions:</p>
                    <ul>
                        <li>Diagram tutorial is in <a href="#" onClick={handleOpen}>here</a>.</li>
                        <li>A tutorial video is here </li>
                    </ul>
                </div>
                <div>
                    <Dialog open={openDialog}
                        style={{
                            right: '0%'
                        }} >
                        <DialogTitle  >
                            <Typography
                                style={{
                                    width: '50%',
                                    margin: 'auto',
                                }}
                                color="textPrimary"
                                gutterBottom
                                variant="h6"
                                align="center"
                            >
                                How to Create a Model
                            </Typography>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>

                            <ul>
                                <li>Name your dataset under Dataset Name.</li>
                                <li>Provide link to training and validation dataset.</li>
                                <ul>
                                    <li>Refer to Show data format if you are confused about the format.</li>
                                    <li>Only one link to your csv file is needed, the ratio between training and validation dataset is determined automatically. Or you can determine the ratio by yourself later in <strong><a href="#datasetdetails"><code>Details</code></a></strong>. You may also click <strong>Link to example</strong>, if you prefer using our publicly available dataset, SoyNAM - Protein quantitative protein trait.</li>
                                </ul>
                                <li>Write the description of your dataset, which is optional, for your own reference only.</li>
                                <li>Click on <strong>Create</strong> button to create your own dataset.</li>
                                <img style={imageStyleInModal} src="https://de.cyverse.org/dl/d/B11748AB-5273-41A5-AEFA-8A194AD0226B/create_dataset.png" alt="create_dataset" />
                            </ul>
                            <ul>
                                <li>Name your dataset under Dataset Name.</li>
                                <li>Provide link to training and validation dataset.</li>
                                <ul>
                                    <li>Refer to Show data format if you are confused about the format.</li>
                                    <li>Only one link to your csv file is needed, the ratio between training and validation dataset is determined automatically. Or you can determine the ratio by yourself later in <strong><a href="#datasetdetails"><code>Details</code></a></strong>. You may also click <strong>Link to example</strong>, if you prefer using our publicly available dataset, SoyNAM - Protein quantitative protein trait.</li>
                                </ul>
                                <li>Write the description of your dataset, which is optional, for your own reference only.</li>
                                <li>Click on <strong>Create</strong> button to create your own dataset.</li>
                                <img style={imageStyleInUserGuide} src="https://de.cyverse.org/dl/d/B11748AB-5273-41A5-AEFA-8A194AD0226B/create_dataset.png" alt="create_dataset" />
                            </ul>
                            <ul>
                                <li>Name your dataset under Dataset Name.</li>
                                <li>Provide link to training and validation dataset.</li>
                                <ul>
                                    <li>Refer to Show data format if you are confused about the format.</li>
                                    <li>Only one link to your csv file is needed, the ratio between training and validation dataset is determined automatically. Or you can determine the ratio by yourself later in <strong><a href="#datasetdetails"><code>Details</code></a></strong>. You may also click <strong>Link to example</strong>, if you prefer using our publicly available dataset, SoyNAM - Protein quantitative protein trait.</li>
                                </ul>
                                <li>Write the description of your dataset, which is optional, for your own reference only.</li>
                                <li>Click on <strong>Create</strong> button to create your own dataset.</li>
                                <img style={imageStyleInUserGuide} src="https://de.cyverse.org/dl/d/B11748AB-5273-41A5-AEFA-8A194AD0226B/create_dataset.png" alt="create_dataset" />
                            </ul>
                            <ul>
                                <li>Name your dataset under Dataset Name.</li>
                                <li>Provide link to training and validation dataset.</li>
                                <ul>
                                    <li>Refer to Show data format if you are confused about the format.</li>
                                    <li>Only one link to your csv file is needed, the ratio between training and validation dataset is determined automatically. Or you can determine the ratio by yourself later in <strong><a href="#datasetdetails"><code>Details</code></a></strong>. You may also click <strong>Link to example</strong>, if you prefer using our publicly available dataset, SoyNAM - Protein quantitative protein trait.</li>
                                </ul>
                                <li>Write the description of your dataset, which is optional, for your own reference only.</li>
                                <li>Click on <strong>Create</strong> button to create your own dataset.</li>
                                <img style={imageStyleInUserGuide} src="https://de.cyverse.org/dl/d/B11748AB-5273-41A5-AEFA-8A194AD0226B/create_dataset.png" alt="create_dataset" />
                            </ul>
                        </DialogContent>
                    </Dialog>

                </div>
                <div>
                    <form
                        onSubmit={submitModel}>
                        <Typography
                            style={formHeadingCss}
                            width="50% !important"
                        >
                            Create a Model
                        </Typography>
                        <Card style={{
                            width: '50%',
                            alignContent: 'left',
                            textAlign: 'left',
                            margin: 'auto',
                            marginBottom: '2%',
                            boxShadow:' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)',
                            fontWeight: '500' 
                        }}>
                            <CardContent>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Model name"
                                    value={modelName}
                                    onChange={e => { setModelName(e.target.value) }}
                                    required
                                />
                                <br></br><br></br>


                                <FormControl required>
                                    <FormLabel>Choose data type (Choose min 1)</FormLabel>
                                    <FormGroup >
                                        {datasetList.map((data) =>
                                            <FormControlLabel key={data.id} control={<Checkbox
                                                value={data.dataset_type_key}
                                                name={data.dataset_type_key}
                                                onChange={(e) => { dataTypeHandler(e) }} />} label={data.dataset_type_name}
                                            />
                                        )}
                                    </FormGroup>
                                </FormControl >

                                <br></br><br></br>
                                <FormControl required>
                                    <FormLabel >Task</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="radio-buttons-group"
                                        value={task}
                                        onChange={e => { taskTypehandler(e) }}
                                    >
                                        {modelTasknames.map((data) =>
                                            <FormControlLabel value={data.task_name} key={data.task_name} control={<Radio required />} label={data.task_name} />
                                        )}
                                    </RadioGroup>
                                </FormControl>

                                <br></br><br></br>
                                <FormControl required>
                                    <FormLabel>Model</FormLabel>
                                    <Select
                                        defaultValue=""
                                        value={model}
                                        onChange={e => { modelhandler(e) }}
                                        required
                                    >
                                        {mlModelTypes.map((data) =>
                                            <MenuItem key={data.model_type} value={data.model_type_key} disabled={!data.chooseable}> {data.model_type}</MenuItem >
                                        )}
                                    </Select>
                                </FormControl>

                                <br></br><br></br>
                                <FormControl required>
                                    <FormLabel >Hyperparameter Option</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="radio-buttons-group"
                                        value={hyperParameter}
                                        onChange={e => { hyperParameterHandler(e) }}
                                    >

                                        {mlHparamModes.map((data) =>
                                            <FormControlLabel value={data.mode_key} key={data.mode_key} control={<Radio />} label={data.mode_name} />
                                        )}

                                    </RadioGroup>
                                </FormControl>
                                <br></br><br></br>
                                {
                                    (hyperParameter == "DEFAULT_MODE") && (<DefaultHyperParam model={model} inputdataType={inputdataType} />)
                                }

                                {
                                    (hyperParameter == "CUSTOMIZE_HYPERPARAMETERS" && model == "DUAL_CNN") && (<DualCNNHyperParams hyperParamvalues={hyperParamvalues} setHyperParamvalues={setHyperParamvalues} />)
                                }

                                {
                                    (hyperParameter == "CUSTOMIZE_HYPERPARAMETERS" && model == "SINGLE_CNN") && (<SingleCNNHyperParams hyperParamvalues={hyperParamvalues} setHyperParamvalues={setHyperParamvalues} />)
                                }

                                {
                                    (hyperParameter == "CUSTOMIZE_HYPERPARAMETERS" && model == "MULTI_CNN") && (<MultiCNNHyperParams hyperParamvalues={hyperParamvalues} setHyperParamvalues={setHyperParamvalues} />)
                                }
                                <br></br>
                                <FormControl>
                                    <FormLabel >Description</FormLabel>
                                    <TextField
                                        type="text"
                                        value={description}
                                        onChange={e => { setDescription(e.target.value) }}
                                    />
                                </FormControl>
                            </CardContent>

                            <CardActions>
                                <Button variant="contained" style={formSubmitButtonCss} type="submit" disabled={(!(isCheckBoxChecked) || (hyperParameter == ""))} size="large">
                                    Create Model
                                </Button>
                            </CardActions>



                            {failAlert ? (<Alert severity="error" >{failMessage}</Alert>) : ''}
                        </Card>
                    </form>
                </div>
            </div >

            <div>
                {dataLoading ? (<div><Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                    <Typography> Dont refresh while creating a Model</Typography>
                </Backdrop></div>) : ''}
            </div>
        </>
    )
}
