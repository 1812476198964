import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, Typography } from '@material-ui/core';
import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';


async function updatePassword(credentials) {
    return fetch('/api/auth/update_password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

export default function UpdatePassword({ emailForgot, setUpdateStatusEnable }) {

    const navigate = useNavigate();

    useEffect(() => {
        if (emailForgot == null) {
            navigate("/forgot_password");
        }
    })


    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [alert, setAlert] = useState(false);

    async function handleUpdatePassword(event) {
        event.preventDefault();

        if (password !== confirmPassword) {
            setAlert(true);
            return;
        }

        // console.log(emailForgot, password, confirmPassword);
        const response = await updatePassword({
            "email": emailForgot,
            "password": password
        });

        setUpdateStatusEnable(response.status);
        navigate("/update_password_status");
    }

    return (
        <div><form >
            <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '80vh' }}>
                <Grid item xs={12}>
                    <Card style={{ boxShadow: ' 0 0 2rem rgb(3 3 3 / 20%), 0 0 0.3rem rgb(3 3 3 / 9%)', }}>
                        <CardHeader title={<Typography variant={"h3"} style={{
                            color: '#2A3F54',
                            fontWeight: 'bold'
                        }}>Update Password</Typography>} />
                        <CardContent >
                            <Typography style={{
                                color: '#2A3F54'
                            }} sx={{ fontSize: 14 }} color="secondary" >
                                Please use strong password ..!!
                            </Typography><br></br>
                            <Typography >
                                <TextField label="Password" fullWidth required variant="outlined" onChange={e => setPassword(e.target.value)} />
                            </Typography><br></br>
                            <Typography >
                                <TextField label="Confirm password" type="password" fullWidth required variant="outlined" onChange={e => setConfirmPassword(e.target.value)} />
                            </Typography>
                            <br></br>
                        </CardContent>
                        <CardActions>

                            <Button style={{
                                margin: 'auto',
                                backgroundColor: '#2A3F54',
                                color: 'white'
                            }} onClick={handleUpdatePassword} size="large" >Submit</Button>
                        </CardActions>
                    </Card>
                    {alert ? (<Alert severity="error" sx={{ backgroundColor: 'lightred' }}>Password does not match</Alert>) : ''}
                </Grid>
            </Grid>
        </form></div>
    )
}
